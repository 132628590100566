import React from 'react'

import { GlobalStyles } from '../../globalStyles'
import 'semantic-ui-css/semantic.min.css'

import {
  LayoutContainer
} from './styles'

export const Layout: React.FC = ({ children }) => {
  return (
      <>
      <GlobalStyles/>
      <LayoutContainer>
        {children}
      </LayoutContainer>
      </>
  )
}
