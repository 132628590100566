import styled from 'styled-components'

export const LayoutContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  word-break: break-word;
`
